import React, { useState, useEffect, useMemo } from 'react';
import * as S from './CookieContainer.styles';
import { Builder } from '@builder.io/react';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const CookieContainer = props => {
  const {
    children,
    attributes,
    cookieKey,
    defaultContainer,
    showDefaultOnMissingCookie,
    checkUrlParams
  } = props;

  const containers = useMemo(() => props.containers || [], [props]);

  const [show, setShow] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(defaultContainer || 0);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (!checked) {
      let queryParams;
      if (checkUrlParams) {
        queryParams = new URLSearchParams(window.location.search);
      }
      containers.map(({ cookieList }, index) => {
        return cookieList?.map(({ keyIfListMultipleType, value, contains }) => {
          if (checkUrlParams) {
            if (queryParams.get(keyIfListMultipleType)) {
              cookies.set(
                keyIfListMultipleType,
                queryParams.get(keyIfListMultipleType) ?? ''
              );
            }
          }
          if (Object.keys(cookies.getAll()).includes(keyIfListMultipleType)) {
            if (cookies.get(keyIfListMultipleType) === value) {
              setShow(true);
              setCurrentIndex(index);
            }
            if (
              contains &&
              cookies.get(keyIfListMultipleType).includes(value)
            ) {
              setShow(true);
              setCurrentIndex(index);
            }
          }
          return null;
        });
      });

      if (!show && showDefaultOnMissingCookie) {
        setShow(true);
        setCurrentIndex(defaultContainer || 0);
      }
    }
    return () => {
      setChecked(true);
    };
  }, [
    children,
    cookieKey,
    containers,
    setCurrentIndex,
    defaultContainer,
    showDefaultOnMissingCookie,
    show,
    setShow,
    checked,
    setChecked,
    checkUrlParams
  ]);

  const noChildren = () => {
    return <S.NoChildren>insert components here</S.NoChildren>;
  };

  const Container = () => {
    return (
      <S.Container
        {...attributes}
        layerId={`LIST MULTIPLE KEYS`}
        editing={Builder.isEditing}
      >
        {children[currentIndex] || noChildren()}
      </S.Container>
    );
  };

  if (show || Builder.isEditing) {
    return <Container />;
  } else {
    return ``;
  }
};

export default CookieContainer;
